import { on } from 'delegated-events';

import { isVisible, findSiblings, slideToggle, slideUp, slideDown } from './utils/utils';

export default function () {
    document.querySelectorAll('.user-orders-list .order-header button').forEach(button => {
        button.addEventListener('click', function () {
            findSiblings(this.parentElement.parentElement).forEach(orderInfo => slideUp(orderInfo.getElementsByClassName('order-detailed-info')[0]));
            slideToggle(this.parentElement.parentElement.querySelector('.order-detailed-info'));
        });
    });

    document.querySelectorAll('.single-saved-cart .buttons-wrapper > i').forEach(i => {
        i.addEventListener('click', function () {
            slideToggle(this.parentElement.nextElementSibling);
        });
    });

    // Guziki w drzewie kategorii
    const tree = document.getElementById('tree');

    if (tree) {
        tree.querySelectorAll('li.t0.parent > a').forEach(li => {
            const button = document.createElement('button');
            button.setAttribute('title', 'Rozwiń kategorię');

            li.appendChild(button);
        });

        tree.querySelectorAll('button').forEach(button => {
            button.addEventListener('click', function (e) {
                e.preventDefault();
                this.parentElement.parentElement.classList.toggle('downarrow');
            });
        });
    }

    on('click', '.gs-filtr-container button', function (e) {
        e.preventDefault();
        this.parentElement.querySelectorAll('ul')[0].classList.toggle('show-all');
    });

    // DETAIL ACCORDION
    on('click', '.detail-accordion-trigger', function () {
        this.classList.toggle('info-visible');
    });

    // DETAIL LOUPE
    on('click', '.detail-loupe', function () {
        const toClick = document.querySelector('.product-images .swiper-slide-active');

        if (toClick) {
            toClick.click();
        }
    });

    // FAQ
    const faqListSpan = document.querySelectorAll('.faq-list span');

    faqListSpan.forEach(span => {
        span.addEventListener('click', function () {
            span.parentElement.classList.toggle('show');
            findSiblings(span).forEach(sibling => slideToggle(sibling));

            const id = span.parentElement.getAttribute('id');

            if (id) {
                if (history.pushState) {
                    history.pushState(null, null, `faq#${id}`);
                } else {
                    location.hash = `faq#${id}`;
                }
            }
        });
    });

    if (window.location.href.indexOf("faq") > -1) {
        faqListSpan.forEach(span => {
            if (span.parentElement.classList.contains('show')) {
                span.nextElementSibling.style.display = 'block';
            }
        });

        let hash = window.location.hash;

        if (hash) {
            hash = hash.substring(1);
            const elem = document.querySelector(`#${hash}`);

            elem.classList.add('show');
            elem.querySelector('article').style.display = 'block';
            elem.parentElement.closest('li').classList.add('show');
            elem.parentElement.style.display = 'block';
        }
    }

    // TOGGLER COMPANY
    on('click', '.cart-toggler', function () {
        let toShow;

        if (this.type === 'checkbox') {
            toShow = this.parentElement.nextElementSibling;
            slideToggle(toShow);
        }

        if (this.type === 'radio') {
            toShow = document.getElementsByClassName('toShowCompany')[0];

            if (this.value === '1' && !isVisible(toShow)) {
                slideDown(toShow);
            }

            if (this.value === '0' && isVisible(toShow)) {
                slideUp(toShow);
            }
        }
    });

    // FILTER
    const filter = document.getElementById('filter');

    if (filter) {
        const body = document.body;
        const isCanvasFilter = document.body.hasAttribute('data-canvas-filter');

        if (isCanvasFilter) { // IF CANVAS FILTER
            on('click', '.filter-trigger', function () {
                if (window.innerWidth < 1280) {
                    body.classList.add('overlay-visible');
                    body.classList.add('show-filter');
                    filter.style.display = 'block';
                } else {
                    document.getElementById('container').classList.toggle('show-side-filter');
                }
            });

            document.addEventListener('mouseup', function (e) {
                const isClickInside = filter.contains(e.target);

                if (!isClickInside) {
                    document.getElementById('container').classList.remove('show-side-filter');
                }
            });
        } else { // IF NOT CANVAS FILTER
            on('click', '.filter-trigger', function() {
                if (window.innerWidth < 1280) {
                    body.classList.add('overlay-visible');
                    body.classList.add('show-filter');
                    filter.style.display = 'block';
                }
            });
        }

        document.querySelector('.close-filter').addEventListener('click', function () {
            if (window.innerWidth < 1280) {
                body.classList.remove('overlay-visible');
                body.classList.remove('show-filter');
                filter.style.display = 'none';
            }
        });

        filter.querySelectorAll('.gs-filtr-container > strong').forEach(strong => {
            strong.addEventListener('click', function () {
                if (window.innerWidth < 1280) {
                    this.parentElement.classList.toggle('show-list');
                }
            });
        });

        filter.querySelector('.close-filter-bottom > span').addEventListener('click', function () {
            if (window.innerWidth < 1280) {
                body.classList.remove('overlay-visible');
                body.classList.remove('show-filter');
                filter.style.display = 'none';
            }
        });
    }
}
